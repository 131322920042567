import { height } from '@mui/system';
import React from 'react';



export const Palette = {
  // blue: "#35dcd9",
  blue: "rgb(143, 243, 235)",
  pink: "rgb(146, 0, 213)",
  pink2: "rgba(146, 0, 213, 0.6)",
  errorRed: "#dc0404",
}

export const borderDebug = {
  borderWidth: 1,
  borderColor: "red",
  borderStyle: "solid",
} as React.CSSProperties
export const ligthBorder = {
  boxShadow: "0px 0px 15px 0px " + Palette.blue,
  borderWidth: 1,
  borderColor: Palette.blue,
  borderStyle: "solid",
} as React.CSSProperties;
export const centerFlex = {
  alignItems: "center",
  justifyContent: "center",
} as React.CSSProperties;
const ligthBorder2 = {
  ...ligthBorder,
  boxShadow: "0px 0px 20px 5px " + Palette.blue,
} as React.CSSProperties;
export const ligthBorderPink = {
  boxShadow: "0px 0px 15px 0px " + Palette.pink,
  borderWidth: 1,
  borderColor: Palette.pink,
  borderStyle: "solid",
  transition: "all 0.2s ease-out"
} as React.CSSProperties;
export const ligthBorderPink2 = {
  ...ligthBorderPink,
  boxShadow: "0px 0px 20px 10px " + Palette.pink,
} as React.CSSProperties;
export const ligthBorderPinkLight = {
  ...ligthBorderPink,
  borderColor: Palette.pink2,
  boxShadow: "0px 0px 10px 0px " + Palette.pink,
} as React.CSSProperties;
export const styles = {
  lightBox: {
    margin: 20,
    padding: 10,
    borderRadius: 10,
    ...ligthBorder
  } as React.CSSProperties,

  h1: { 
    fontFamily: 'Cinzel',
    fontSize: 40,
    color: Palette.blue,
    fontWeight: 500,
    lineHeight: "28pt" 
  },
  h2: { 
    fontFamily: 'Cinzel',
    fontWeight: 500,
    fontSize: 30,
    color: Palette.blue,
  },
  hoopSpecRowTitle: {
    // borderBottom: Palette.pink,
    // borderBottomStyle: "solid",
    // borderWidth: 1,
    marginRight: 10,
    marginTop: 0,
    minWidth: 120,
    color: Palette.pink
    // fontWeight: 600,
  } as React.CSSProperties,

  hoopSpecRow: {
    // width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 3,
    paddingLeft: 30,
  } as React.CSSProperties,

  hoopSpecRowIcon: {
    // flexBasis: 1,
    marginTop: 7,
    marginRight: 10,
    width: 30,
    color: Palette.pink
  } as React.CSSProperties,
  hoopSpecRowText: {
    // paddingTop: 3,
    // display: "flex", flexDirection: "row"//, alignItems: "center",
    // flexBasis: 1,
  } as React.CSSProperties,

  hoopSpecBlock: {
    ...ligthBorder,
    paddingBottom: 30,
    paddingTop: 50,
    // paddingBottom: 50,
    borderRadius: 20,
    marginBottom: 60,
    width: 800,
    maxWidth: "90vw",
    // width: 200,
    // minWidth: 200,
    lineHeight: 2,
    // flex: 1,
  } as React.CSSProperties,

  hoopSpecTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 30,
    height: 50,
    marginBottom: 30,
    color: Palette.pink
  } as React.CSSProperties,

  bigFont: {
    fontFamily: 'Cinzel',
    fontWeight: 500
  } as React.CSSProperties,

  normalFont: {
    fontFamily: "\"The Antiqua B\", Georgia, Droid-serif, serif"
  } as React.CSSProperties
};
