import React, { createContext, PropsWithChildren, useContext, useState } from "react"
import { FlexColumn } from "./components/Flexbox";
import { ligthBorderPink2 } from "./style";



const modalContext = createContext({
  visible: false,
  setVisible: (b : boolean) => {},
  setContent: (content: JSX.Element) => {}
});

export const ModalProvider = (props : PropsWithChildren<{}>) => {

  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(<></>);
  const [opacity, setopacity] = useState(0);
  const transitionTime = 500;
  return <modalContext.Provider value={{
    visible, setVisible: (b : boolean) => {
      if (b)
      {
        setVisible(true);
        setTimeout(() => setopacity(1), 1);
      }
      else
      {
        setopacity(0);
        setTimeout(() => setVisible(false), transitionTime);

      }
    }, setContent
  }}>
    {
      visible ? <div style={{
        display: "flex",
        transition: `all ${transitionTime/1000}s ease-out`,
        opacity: opacity,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0009",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 10,
        top: 0,
        left: 0,
      }} onClick={() => {
        setopacity(0);
        setTimeout(() => setVisible(false), transitionTime);

      }}>
        (<FlexColumn center style={{ transition: `all 0.5s ease-out`, padding: 40, ...ligthBorderPink2, borderRadius: 20, backgroundColor: "#0009" }} onClick={e => {
          e.stopPropagation();
        }}>
        {content}
        </FlexColumn>
        </div> : null
    }
    {props.children}
  </modalContext.Provider>
}

export function useModal() {
  return useContext(modalContext);
}