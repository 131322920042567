import { BiTime } from "react-icons/bi";
import { BsBatteryCharging } from "react-icons/bs";
import { CgMenuMotion } from "react-icons/cg";
import { FiBluetooth, FiRefreshCw } from "react-icons/fi";
import { GiMagicPortal, GiSmartphone, GiBattery75 } from "react-icons/gi";
import {
  IoLogoApple,
  IoLogoAndroid,
  IoMdClock,
  IoMdBatteryDead,
} from "react-icons/io";
import { MdLightbulbOutline, MdUpdate, MdSettings } from "react-icons/md";
import { useIntl, FormattedMessage } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import { CarouselNavButton } from "../CarouselNavButton";
import { FlexColumn, Flex, FlexRow } from "../components/Flexbox";
import { SectionTitle } from "../components/SectionTitle";
import { SpacerV, SpacerH } from "../Spacer";
import { styles, ligthBorderPinkLight, centerFlex, ligthBorder } from "../style";
import { Shop } from "../Shop";
import { ContactPage } from "./ContactPage";
import { BigActionButton } from "../BigActionButton";
import { Page } from "../components/Page";
import { useOpenGraphMetaTags } from "../OpenGraphTags";

const HoopSection = () => {
  const intl = useIntl();
  return (
    <div
      id="theHoop"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <SectionTitle skipLineSeparator={true}>
        <img
          src={require("./icons/live.png")}
          width={50}
          style={{ marginRight: 30, color: Palette.pink }}
        />
        <FormattedMessage defaultMessage="Le cerceau" />
      </SectionTitle> */}

      {/* <img src="/hoop1.jpg" width={500} style={{ borderWidth: 0 }} /> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // flexWrap: "wrap",
          alignItems: "center",
          marginTop: -20,
        }}
      >
        {/* <img
          src="/photo1.png"
          // src="/red_mode.png"
          alt={intl.formatMessage({
            defaultMessage: "Réalisée avec le mode lightpainting",
          })}
          style={{ width: 400 }}
        /> */}

        <ul
          style={{
            width: 700,
            maxWidth: "80vw",
            textJustify: "inter-word",
            listStyleType: "circle",
            margin: 0,
            padding: 0,
          }}
        >
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage
                defaultMessage="Le Performer Hoop est un cerceau lumineux intelligent, hautement personnalisable via une application, créé sur
                mesure à la demande. Fabriqué à Lyon, il est disponible en
                livraison gratuite dans toute la France."
              />
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage
                defaultMessage="Sa technologie interactive lui permet de réagir aux sons et aux
                mouvements. Il est piloté par une application moderne et
                ergonomique qui permet une utilisation simple, ou plus poussée,
                selon les besoins et les envies de chacun."
              />
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage
                defaultMessage="Découvrez ses nombreuses fonctionnalités, telles que la création ou
                l'importation de modes, ou encore la programmation de
                chorégraphies calées sur les musiques de votre choix."
              />
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage
                defaultMessage="Tout achat de cerceau inclut 4 batteries rechargeables ainsi qu'un
                chargeur, pour une totale autonomie !"
              />
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage
                defaultMessage="HoopArtisan propose un service après-vente réactif, qui inclut 2
                réparations gratuites, puis des tarifs arrangeants."
              />
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <FormattedMessage defaultMessage="Pour toute question," />{" "}
              <a href="/contact" style={{ textDecoration: "underline" }}>
                <FormattedMessage defaultMessage="n'hésitez pas à nous contacter ici" />
              </a>
            </p>
          </li>
        </ul>
        <SpacerV size={30} />
        {/* TECHNOLOGY  */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <GiMagicPortal size={50} style={{ marginRight: 10 }} />
            <FormattedMessage defaultMessage="Fonctionnalités" />
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <MdLightbulbOutline
              size={20}
              style={{ ...styles.hoopSpecRowIcon }}
            />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Accédez à tous les modes HoopArtisan" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <MdLightbulbOutline
              size={20}
              style={{ ...styles.hoopSpecRowIcon }}
            />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Création de modes personnalisés" />
            </div>
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <MdLightbulbOutline
              size={20}
              style={{ ...styles.hoopSpecRowIcon }}
            />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="60 leds / mètre, gap de 7.5cm" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <GiSmartphone size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Contrôlable via bluetooth" />
              <FiBluetooth size={20} />{" "}
              <FormattedMessage defaultMessage="par l'application iPhone" />{" "}
              <IoLogoApple size={20} />
              <FormattedMessage defaultMessage="et Android" />{" "}
              <IoLogoAndroid size={20} />
            </div>
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <IoMdClock size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Synchronisation de plusieurs cerceaux" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <MdUpdate size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Mise à jour du cerceau simplifiée via l'application" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <CgMenuMotion size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Réagit au son et au mouvement" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <GiBattery75 size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Accès au niveau de batterie du cerceau" />
            </div>
          </div>
        </div>

        {/* SPACER  */}
        {/* <div style={{ width: 30 }}></div> */}
        {/* BATTERY  */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <BsBatteryCharging size={50} style={{ marginRight: 10 }} />
            <FormattedMessage defaultMessage="Batterie" />
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <IoMdBatteryDead size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="4 batteries et un chargeur fournis pour chaque cerceau acheté" />
            </div>
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <FiRefreshCw size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Interchangeable et rechargeable" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <BiTime size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormattedMessage defaultMessage="Autonomie à puissance maximale:" />
                <ul style={{ padding: 0, paddingLeft: 40, margin: 0 }}>
                  <li>16mm, 5/8: 25 min</li>
                  <li>19mm, 3/4: 50 min</li>
                </ul>
                <FormattedMessage defaultMessage="Autonomie à puissance moyenne:"/>
                <ul style={{ padding: 0, paddingLeft: 40, margin: 0 }}>
                  <li>16mm, 5/8: 45 min</li>
                  <li>19mm, 3/4: 1h45</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Options configurables */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <MdSettings size={50} style={{ marginRight: 10 }} />
            Options
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            {/* <TbChartCircles size={20} style={{ ...styles.hoopSpecRowIcon }} /> */}
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Matériaux:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>Polypro</li>
                {/* <li>HDPE</li> */}
              </ul>
            </div>
          </div>
          <SpacerV size={10} />
          <div style={{ ...styles.hoopSpecRow }}>
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Épaisseur:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>
                  20mm - 3/4 <FormattedMessage defaultMessage="inch" />
                </li>
                <li>
                  16mm - 5/8 <FormattedMessage defaultMessage="inch" />
                </li>
              </ul>
            </div>
          </div>
          <SpacerV size={10} />
          <div style={{ ...styles.hoopSpecRow }}>
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Diamètre:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>
                  <FormattedMessage defaultMessage="de 50cm à 90cm" />
                </li>
                <li>
                  {" "}
                  <FormattedMessage defaultMessage="Autres dimensions sur demande" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AppSection = () => {
  const isMobile = window.innerWidth < 900;
  const navButtonSize = isMobile ? 25 : 50;

  const screenShotImageStyle = {
    height: 450,
    objectFit: "contain",
    ...(!isMobile ? ligthBorderPinkLight : null),
    borderRadius: 10,
    padding: 10,
  } as React.CSSProperties;

  return (
    <FlexColumn center id="app" style={{ width: "100%" }}>
      <SectionTitle>
        <div>
          <GiSmartphone size={50} style={{ marginRight: 10 }} />
          <IoLogoAndroid size={50} style={{ marginRight: 10 }} />
          <IoLogoApple size={50} style={{ marginRight: 30 }} />
        </div>
        {/* <BsCart size={30} style={{ marginRight: 10 }} /> */}
        <FormattedMessage defaultMessage="L'application mobile" />
      </SectionTitle>

      <div style={{ maxWidth: "90vw", ...centerFlex }}>
        <div style={{ textAlign: "center" }}>
          <FormattedMessage
            defaultMessage="Le cerceau est programmable via une application mobile disponible sur
          Android et IOS."
          />
        </div>
        <Flex
          //column
          row={!isMobile ? true : false}
          column={isMobile ? true : false}
          center
          style={{ maxWidth: "90vw" }}
        >
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.andyHoopled.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Disponible sur Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                style={{ width: "250px" }}
              />
            </a>
          </div>
          <SpacerH size={10} />
          <a
            href="https://apps.apple.com/us/app/hoop-artisan/id6444842033?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/fr-fr?size=250x83&amp;releaseDate=1681862400"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
        </Flex>
      </div>
      <SpacerV size={20}></SpacerV>
      <FlexColumn
        center
        style={{
          width: 800,
          maxWidth: "90vw",
          ...ligthBorder,
          borderRadius: 20,
        }}
      >
        <FlexColumn center style={{ maxWidth: 500 }}>
          <div style={{ ...styles.bigFont, fontSize: "18pt", margin: 20 }}>
            <FormattedMessage defaultMessage="Fonctionnalités" />
          </div>
          <Carousel
            showThumbs={false}
            showStatus={false}
            emulateTouch={true}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <CarouselNavButton
                clickHandler={clickHandler}
                active={hasPrev}
                isPrev={true}
              />
            )}
            renderArrowNext={(clickHandler, hasPrev) => (
              <CarouselNavButton
                clickHandler={clickHandler}
                active={hasPrev}
                isPrev={false}
              />
            )}
          >
            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img alt="Hoop Manager"
                  src="/app-screenshots/hoopManager.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Connexion des cerceaux" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Affichage du niveau de batterie" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Mise à jour des cerceaux en 1 clic" />
                </li>
              </ul>
            </FlexColumn>

            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Modes"
                  src="/app-screenshots/modes.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Bibliothèque ergonomique" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Des packs de modes HoopArtisan synchronisés" />
                </li>
              </ul>
            </FlexColumn>
            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Paint"
                  src="/app-screenshots/paint.jpg"
                  style={{
                    height: 200,
                    maxWidth: "90vw",
                    objectFit: "contain",
                    ...(!isMobile ? ligthBorderPinkLight : null),
                    borderRadius: 10,
                    padding: 10,
                  }}
                />
                <SpacerV size={10} />
                <img
                  alt="Simulator"
                  src="/app-screenshots/simulator.jpg"
                  style={{
                    height: 200,
                    maxWidth: "90vw",
                    objectFit: "contain",
                    ...(!isMobile ? ligthBorderPinkLight : null),
                    borderRadius: 10,
                    padding: 10,
                  }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Création de modes personnalisés" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Un simulateur pour visualiser les modes dans l'application" />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="Réglages de nombreux paramètres (taille, accéléromètre,
                    son…)"
                  />
                </li>
              </ul>
            </FlexColumn>

            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Choreography"
                  src="/app-screenshots/choregraphy.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Programmation de chorégraphies" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Synchronisation avec la musique" />
                </li>
              </ul>
            </FlexColumn>
          </Carousel>
        </FlexColumn>
      </FlexColumn>
      <SpacerV size={20} />
      <FlexColumn
        center
        style={{
          width: 800,
          maxWidth: "90vw",
          ...ligthBorder,
          borderRadius: 20,
        }}
      >
        <div style={{ ...styles.bigFont, fontSize: "18pt", margin: 20 }}>
          <FormattedMessage defaultMessage="Tutoriel" />
        </div>

        <iframe
          id="ytplayer"
          width="640"
          height="360"
          style={{ maxWidth: "80vw" }}
          src="https://www.youtube.com/embed/lvGhsyx-jgk?autoplay=0&origin=http://hoopartisan.fr"
        ></iframe>

        <SpacerV size={20} />
      </FlexColumn>
    </FlexColumn>
  );
};


export const PerformerHoopPage = () => {

    const intl = useIntl();
    useOpenGraphMetaTags({
        title: "Hoop Artisan - Performer Hoop",
        url: location.href,
        description: intl.formatMessage({ defaultMessage: "HoopArtisan fabrique des cerceaux lumineux et programmables qui réagissent aux mouvements et au son." }),
        type: "website",
        image: "/performerhoop_banner.webp"
      });

    
    return <Page headerAutoHide={false} style={{marginTop: 0}}>
        <h1>
          <FlexRow center style={{marginTop: 60}}>
          <img  alt="Performer Hoop" style={{width: 800, maxWidth: "100vw"}} src="/performerhoop_banner.webp"></img>
          </FlexRow>
        </h1>
        {/* <img style={{width: 500, marginTop: -100, maxWidth: "80vw"}} src="/halloween_logo.png"></img> */}
        {/* <img style={{width: 500, marginTop: -100, maxWidth: "80vw"}} src="/halloween_logo.png"></img> */}
        <SpacerV size={20}/>
        <FlexRow style={{width: 800, maxWidth: "80vw", justifyContent: "flex-end", alignItems: "baseline"}}>
        <div style={{fontSize: 20}}>450€</div>
        {/* <div style={{fontSize: 30, color: "#orange", marginLeft: 10}}>375€</div> */}
          <SpacerH size={20}/>
          <BigActionButton href="#shop"><FormattedMessage defaultMessage="Commander"/></BigActionButton>
        </FlexRow>
        <SpacerV size={50} />
        <HoopSection/>
        <AppSection/>
        {/* <ContactSection/> */}
        <Shop />
    </Page>
}
