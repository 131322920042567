import { PropsWithChildren } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { FlexColumn } from "./Flexbox";

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const Page = (props: PropsWithChildren<{headerAutoHide?: boolean, withPaddingTop? : boolean} & DivProps>) => {
    const { style, headerAutoHide, children, ...divParams} = props;

    return <FlexColumn centerH fillParent
    style={{
      backgroundColor: "rgba(0,0,0,0.5)",
      marginTop: 80,
      ...(props.withPaddingTop ? {paddingTop: 50} : {}),
      ...style
    }}
    {...divParams}
  >
    <Header alwaysVisible={headerAutoHide === undefined ? true: !headerAutoHide} />
    {props.children}
    <Footer />
  </FlexColumn>;
};


export const NormalWidthContainer = (props: PropsWithChildren<DivProps>) => { 
    const { style, children, ...divParams} = props;

    return <div style={{...style, width: 700, maxWidth: "80vw", }} {...divParams}>
    {children}
    </div>
}


export const MaxWidthContainer = (props: PropsWithChildren<DivProps>) => { 
    const { style, children, ...divParams} = props;

    return <div style={{...style, width: "100%", maxWidth: "80vw", }} {...divParams}>
    {children}
    </div>
}