import { PropsWithChildren } from "react";
import { styles } from "../style";

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const H1 = (props: PropsWithChildren<DivProps>) => {
  const { style, children, ...divParams } = props;

  return (
    <h1 style={{ ...style, ...styles.h1 }} {...divParams}>
      {children}
    </h1>
  );
};

export const H2 = (props: PropsWithChildren<DivProps>) => {
  const { style, children, ...divParams } = props;

  return (
    <h2 style={{ ...style, ...styles.h2 }} {...divParams}>
      {children}
    </h2>
  );
};
