import { CSSProperties } from "react";
import { ligthBorderPink } from "../style";


export const LineSeparator = (params: { style?: CSSProperties; }) => {
  return (
    <div
      style={{
        ...ligthBorderPink,
        borderTopWidth: 0,
        height: 0,
        width: "100%",
        ...params.style,
      }}
    ></div>
  );
};
