export const ROUTES = {
  home: "/",
  performer: "/performer-smart-led-hoop",
  party: "/party-smart-led-hoop",
  privacy: "/privacy",
  batteriesSecurity: "/battery-security",
  warranty: "/warranty",
  contact: "/contact",
  legal: "/legal"
};

export function getLocalizedRoute(route : string, lang : "fr" | "en")  {
  if (route.startsWith("/"))
    return `/${lang}${route}`
  else 
    return `/${lang}/${route}`
}
