import { NormalWidthContainer, Page } from "./components/Page";
import { H1, H2 } from "./components/TitleTags";
import { useLanguageSelector } from "./intlMessages";

export const BatterySecurityNotesFR = () => {
  return (
    <div>
      <H1>Notice d’Utilisation et de Sécurité pour les Batteries au Lithium</H1>

      <H2>Avertissement</H2>
      <p>
        Les appareils HoopArtisan utilisent des batteries rechargeables au
        lithium. L’utilisation incorrecte des batteries au lithium peut
        entraîner des blessures graves ou des dommages matériels. Il est
        important de lire et de comprendre toutes les instructions avant
        d’utiliser la batterie.
      </p>

      <H2>Utilisation</H2>
      <ul>
        <li>
          <strong>Chargement :</strong> Utilisez uniquement le chargeur fourni
          avec la batterie.
        </li>
        <li>
          <strong>Stockage :</strong> Stockez la batterie dans un endroit frais
          et sec, à l’écart de la lumière directe du soleil.
        </li>
        <li>
          <strong>Manipulation :</strong> Manipulez la batterie avec soin.
          Évitez de la laisser tomber ou de la soumettre à des chocs violents.
        </li>
        <li>
          <strong>Utilisation :</strong> Enlever la batterie de l'appareil et rechargez la dès
          qu'elle atteint un état qui ne permet plus à l'appareil de fonctionner
          correctement.
        </li>
      </ul>

      <H2>Sécurité</H2>
      <ul>
        <li>
          <strong>Ne pas surcharger :</strong> Ne laissez jamais la batterie se
          charger sans surveillance.
        </li>
        <li>
          <strong>Éviter l’exposition à la chaleur :</strong> N’exposez pas la
          batterie à des températures élevées ou à des flammes. Cela pourrait
          provoquer une fuite ou une explosion.
        </li>
        <li>
          <strong>Ne pas démonter :</strong> Ne tentez jamais de démonter la
          batterie. Cela pourrait provoquer un court-circuit et entraîner un
          incendie. Ne jamais utiliser une batterie endommagée.
        </li>
        <li>
          <strong>Élimination :</strong> Ne jetez pas la batterie avec les
          ordures ménagères. Apportez-la à un centre de recyclage approprié.
        </li>
        <li>
          <strong>Court-circuit :</strong> Ne jamais court-circuiter la
          batterie. Ne jamais mettre un trousseau de clé proche d'une batterie,
          ou tout objet métallique capable de mettre en contact les deux bornes
          de la batterie.
        </li>
      </ul>
    </div>
  );
};

export const BatterySecurityNotesEN = () => {
  return (
    <div>
      <H1>User Guide and Safety Instructions for Lithium Batteries</H1>

      <H2>Warning</H2>
      <p>
        HoopArtisan devices use rechargeable lithium batteries. Incorrect use of
        lithium batteries can result in serious injuries or material damage. It
        is important to read and understand all instructions before using the
        battery.
      </p>

      <H2>Usage</H2>
      <ul>
        <li>
          <strong>Charging:</strong> Use only the charger provided with the
          battery.
        </li>
        <li>
          <strong>Storage:</strong> Store the battery in a cool and dry place,
          away from direct sunlight.
        </li>
        <li>
          <strong>Handling:</strong> Handle the battery with care. Avoid
          dropping it or subjecting it to violent shocks.
        </li>
        <li>
          <strong>Operation:</strong> Remove the battery from the device and charge it as soon
          as it reaches a state that no longer allows the device to function
          properly.
        </li>
      </ul>

      <H2>Safety</H2>
      <ul>
        <li>
          <strong>Do not overcharge:</strong> Never leave the battery charging
          unattended.
        </li>
        <li>
          <strong>Avoid exposure to heat:</strong> Do not expose the battery to
          high temperatures or flames. This could cause leakage or an explosion.
        </li>
        <li>
          <strong>Do not disassemble:</strong> Never attempt to disassemble the
          battery. This could cause a short circuit and result in a fire. Never
          use a damaged battery.
        </li>
        <li>
          <strong>Disposal:</strong> Do not dispose of the battery with
          household waste. Take it to an appropriate recycling center.
        </li>
        <li>
          <strong>Short-circuit:</strong> Never short-circuit the battery. Never
          place a keychain near a battery or any metal object capable of making
          contact with both terminals of the battery.
        </li>
      </ul>
    </div>
  );
};

export const BatterySecurity = () => {
    const lang = useLanguageSelector();
    return <Page withPaddingTop>
        <NormalWidthContainer>
        {lang.language === "fr" ?<BatterySecurityNotesFR/>: <BatterySecurityNotesEN/>}
        </NormalWidthContainer>
    </Page>
}