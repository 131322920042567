import { FormattedMessage } from "react-intl";
import { H1 } from "../components/TitleTags";
import { NormalWidthContainer, Page } from "../components/Page";

export const MentionLegales = () => {
  return (
    <Page>
      <NormalWidthContainer>

      <H1>
        Mentions légales
      </H1>

      <p>
        <strong>
          Conformément à la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique
        </strong>
        ,
        il est précisé aux utilisateurs du site HoopArtisan l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
      </p>

      <h2>
        Éditeur du site
      </h2>
      <ul>
        <li>
          <strong>
            Nom ou raison sociale:
          </strong>{" "}
          Matthieu Garrigues
        </li>
        <li>
          <strong>
            Statut juridique:
          </strong>{" "}
          Auto-entrepreneur
        </li>
        <li>
          <strong>
            Adresse du siège social:
          </strong>{" "}
          11 avenue du boutarey, 69580 Sathonay-Camp
        </li>
        <li>
          <strong>
            Numéro SIRET:
          </strong>{" "}
          78851791000022
        </li>
      </ul>

      <h2>
        Hébergeur du site
      </h2>
      <ul>
        <li>
          <strong>
            Nom de l'hébergeur:
          </strong>{" "}
          OVH
        </li>
        <li>
          <strong>
            Adresse:
          </strong>{" "}
          OVH SAS, 2 rue Kellermann, 59100 Roubaix, France
        </li>
      </ul>

      <h2>
        Propriété intellectuelle
      </h2>
      <p>
        L'ensemble des éléments du site (textes, images, graphismes, logo, icônes, etc.) sont protégés par le droit d'auteur, le droit des marques et le droit des bases de données. Toute reproduction, représentation, modification, publication, transmission, dénaturation, totale ou partielle du site ou de son contenu, par quelque procédé que ce soit, sans l'autorisation expresse de l'éditeur est interdite et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
      </p>

      <h2>
        Collecte de données personnelles
      </h2>
      <p>
        Les informations collectées sur ce site sont nécessaires à la gestion de vos commandes. Conformément à la loi 'Informatique et Libertés' du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès, de rectification et d’opposition aux données vous concernant. Pour exercer ce droit, vous pouvez nous contacter à l'adresse e-mail suivante : 
        <a href="mailto:contact@hoopartisan.fr">
          contact@hoopartisan.fr
        </a>
        .
      </p>

      <h2>
        Cookies
      </h2>
      <p>
        Le site HoopArtisan peut utiliser des cookies pour assurer un bon fonctionnement du site et améliorer l'expérience utilisateur. En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies. Vous avez la possibilité de désactiver les cookies en vous rendant dans les paramètres de votre navigateur.
      </p>

      {/* <h2>
        Conditions générales de vente
      </h2> */}

      {/* <p>
        Les conditions générales de vente (CGV) applicables aux produits vendus sur ce site sont disponibles sur
        <a href="[lien vers les CGV]" target="_blank">
          [lien vers les CGV]
        </a>
        .
        Il est recommandé aux clients de lire attentivement ces conditions avant de passer commande.
      </p> */}

      <h2>
        Responsabilité
      </h2>
      <p>
        L'éditeur ne saurait être tenu responsable en cas de dysfonctionnement technique du site, ni de tout dommage direct ou indirect qui pourrait en découler pour l'utilisateur.
      </p>
      </NormalWidthContainer>
    </Page>
  );
};
