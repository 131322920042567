import { useState } from "react";
import { LinkOrA } from "./components/LinkOrA";
import { useScrollTop } from "./pages/Homepage";
import { ROUTES } from "./ROUTES";
import { styles } from "./style";
import { LanguageSeletorWidget } from "./intlMessages";
import { LocalizedLink } from "./components/localizedLink";

export const Header = (params: { alwaysVisible?: boolean }) => {
  const [visible, setVisible] = useState(params.alwaysVisible || false);
  const [menuVisible, setMenuVisible] = useState(false);
  useScrollTop((scrolltop) => {
    // console.log(scrolltop > 2000);
    if (!params.alwaysVisible) setVisible(scrolltop > window.innerHeight / 2);
  });
  return (
    <div>    
    {!visible && <div style={{
      position: "fixed",
      top: 0,
      right: 0,
      padding: 10,
      zIndex: 10001,
      opacity: visible ? 0 : 1,
      display: visible ? "none" : "block",
    }}>
      <LanguageSeletorWidget/>
    </div>}
    <div
      style={{
        opacity: visible ? 1 : 0,
        // display: visible ? "flex" : "none",
        transition: "all 1s ease-out",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1),rgba(0,0,0,1), rgba(0,0,0,0.0))",
        // backgroundColor: "rgba(0,0,0,0.7)",
        position: "fixed",
        // paddingTop: 20,
        // top: 100,
        top: visible ? 0 : -50,
        left: 0,
        // paddingTop: 10,
        padding: 10,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          maxWidth: 900,
          padding: 10,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          zIndex: 10000,
        }}
      >
        <LinkOrA to="/#home">
          {/* <a href="#"> */}
          <img alt="HoopArtisan Logo" src="/logo_512-64.webp" />
          {/* </a> */}
        </LinkOrA>
        <div style={{width: 10}} />
        <LocalizedLink href={ROUTES.performer} style={{ ...styles.bigFont, textAlign: "center"  }}>
          Performer Hoop
        </LocalizedLink>
        <div style={{width: 10}} />
        <LocalizedLink href={ROUTES.party} style={{ ...styles.bigFont, textAlign: "center" }}>
          Party Hoop
        </LocalizedLink>

        <div style={{width: 10}} />
        <LocalizedLink href={ROUTES.contact} style={{ ...styles.bigFont, textAlign: "center" }}>
        Contact
        </LocalizedLink>
        <div style={{width: 10}} />

        <LanguageSeletorWidget/>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LinkOrA to="/#contact">
            <MdMail size={30} />
          </LinkOrA>
          <SpacerH size={15} />
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              setMenuVisible(true);
              return false;
            }}
          >
            <BsThreeDotsVertical size={30} />
          </a>
          <SpacerH size={10} />
          <Menu visible={menuVisible} setVisible={setMenuVisible} />
        </div> */}
      </div>
    </div>
    </div>

  );
};
